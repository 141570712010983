import {
    MarketDataPriceEvent,
    MarketDataServiceClient,
    MarketDataServiceDefinition
} from '@/compiled_proto/com/celertech/marketdata/api/notification/MarketDataServiceProto';
import { simpleChartConfig } from '@/config/chart';
import { useAppDispatch, useAppSelector } from '@/state/hooks';
import { addBidAsk, fetchUserMarkets, generatePair, selectMarketPairs } from '@/state/reducers/marketPairSlice';
import { IChartingLibraryWidget } from '@/types/charting_library';
import useCSSVariables from '@/utils/hooks/useCSSVariables';
import { grpc } from '@improbable-eng/grpc-web';
import { Metadata, createChannel, createClient } from 'nice-grpc-web';
import { useCallback, useEffect, useRef } from 'react';
import { useLocale } from 'react-aria';
import instrumentConfig from './config/instruments';
import { getLocalChartState, initChartSettings } from './helpers/chartHelper';
import { addMarket } from './services/MarketService';
import { asyncIterable } from './state/middleware/ChartDataMiddleware';
import { selectCredentials } from './state/reducers/authSlice';
import { onPriceTick } from './state/reducers/chartDataSlice';
import { useSimpleDatafeed } from './utils/hooks/useSimpleDatafeed';
import useToast from './utils/hooks/useToast';
import { convertPriceEvent } from './utils/pricebook';

const exchangeCode = window.config.integration.celertech.exchangeCode || 'XCEL';
const livePricesType = window.config.modules.chart?.livePricesType || 'bid';

const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl, grpc.WebsocketTransport());

const marketDataServiceClient: MarketDataServiceClient = createClient(MarketDataServiceDefinition, wsChannel);

const queryString = location.search;
const params = new URLSearchParams(queryString);
const symbol = params.get('symbol') || 'BTC/USD';

export default function Chart() {
    const dispatch = useAppDispatch();

    const [toastError] = useToast();
    const credentials = useAppSelector(selectCredentials);
    const pairs = useAppSelector(selectMarketPairs);

    const { locale } = useLocale();
    const datafeed = useSimpleDatafeed(symbol);
    const variables = useCSSVariables();

    const widgetRef = useRef<IChartingLibraryWidget | null>(null);

    const setupMarketSubscription = useCallback(async () => {
        const symbolExist = pairs.find((pair) => pair.netdania === symbol);
        const priceChangeFilter = (celerCode: string) => celerCode === symbolExist?.celer;
        if (credentials) {
            if (symbol && symbolExist) {
                const subscription = marketDataServiceClient.subscribeToPrices(asyncIterable(), {
                    metadata: Metadata({
                        'authorization-token': credentials.authToken
                    })
                });

                // dispatch action for incoming data - to be reduced in celerMarketSlice
                const callback = async (marketSub: MarketDataPriceEvent) => {
                    if (!marketSub.isHeartBeat && marketSub.fullSnapshot?.securityCode) {
                        const market = generatePair(marketSub.fullSnapshot.securityCode);
                        const converted = convertPriceEvent(marketSub);
                        if (converted) {
                            const timestamp = parseInt(converted.snapshotMillis);
                            const bestBid = converted.priceBook.find((pb) => pb.type === 'bids')?.price;
                            const bestAsk = converted.priceBook.find((pb) => pb.type === 'asks')?.price;
                            const bestMid = converted.priceBook.find((pb) => pb.type === 'midprice')?.price;
                            if (bestBid && bestAsk && bestMid) {
                                if (priceChangeFilter(market.celer)) {
                                    dispatch(
                                        onPriceTick({
                                            symbol: market.netdania,
                                            price: livePricesType === 'mid' ? bestMid : bestBid,
                                            timestamp
                                        })
                                    );
                                }
                                dispatch(addBidAsk({ celerPair: market.celer, bid: bestBid, ask: bestAsk }));
                            }
                            // dispatch(updateOrderBook(converted));
                        }
                    }
                };

                // forEachAsync(subscription, callback).catch((error) => {
                //     console.error({ 'SingleChartDataMiddleware subscription': error });
                //     if (!(error instanceof ClientError)) {
                //         console.log({ 'Price data subscription': 'Error received, retrying connection...' });
                //         setupMarketSubscription();
                //     }
                // });

                let marketExchangeCode = exchangeCode;
                let marketSettlementType = 'SP';

                if (instrumentConfig[symbol] && instrumentConfig[symbol].type === 'Index') {
                    marketExchangeCode = 'CFD';
                    marketSettlementType = 'TOM';
                }

                await addMarket(credentials, {
                    securityCode: symbol,
                    exchangeCode: marketExchangeCode,
                    settlementType: marketSettlementType
                });
            }
        } else {
            toastError({
                title: 'No Live Prices Available',
                body: 'Seems like your user session has ended, please re-login to see live prices again',
                persist: true
            });
        }
    }, [symbol, pairs, credentials]);

    async function initChart() {
        window.chartLoaded = false;
        const chartState = getLocalChartState();
        if (chartState) chartState.charts[0].panes[0].sources[0].state.symbol = symbol;
        else window.chartLoaded = true;

        const TVWidget = new window.TradingView.widget(simpleChartConfig(symbol, locale, datafeed, variables));
        window.TVWidget = TVWidget;
        TVWidget.onChartReady(() => {
            widgetRef.current = TVWidget;
            if (chartState) {
                TVWidget.load(chartState);
                window.chartLoaded = true;
            }
            initChartSettings(TVWidget, variables);
        });
        await setupMarketSubscription();
    }

    useEffect(() => {
        if (!params.get('symbol')) {
            history.replaceState({}, '', 'chart?symbol=BTC/USD');
        }
        dispatch(fetchUserMarkets()).then(initChart);
    }, []);

    return <div id="tv_chart_container" className="h-full" />;
}
