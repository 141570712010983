import { logToServer } from '@/services/LogService';
import { Notification, pushNotification } from '@/state/reducers/notificationSlice';
import { store } from '@/state/store';
import { getAssetName } from '@/utils/symbolMapping';
import { Fragment } from 'react';
import { Side } from '../compiled_proto/com/celertech/marketdata/api/enums/SideProto';
import { OrderStatus } from '../compiled_proto/com/celertech/orderrouting/api/enums/OrderStatusProto';
import { OrderType } from '../compiled_proto/com/celertech/orderrouting/api/enums/OrderTypeProto';
import { TimeInForceType } from '../compiled_proto/com/celertech/orderrouting/api/enums/TimeInForceTypeProto';
import {
    CreateOrderRequestRejectDownstreamEvent,
    FxOrderSnapshotDownstreamEvent
} from '../compiled_proto/com/celertech/orderrouting/api/order/DownstreamOrderProto';
import { capitalize, capitalizeAll, getQtyFormatter } from '../utils/format';
// import { getOtherCurrency } from './currencyHelper';

export const toastOrderRejectedStatus = (event: CreateOrderRequestRejectDownstreamEvent) => {
    const toast = getOrderRejectedStatusToastConfig(event);
    if (toast) {
        store.dispatch(pushNotification(toast.notification as Notification));
        // Promise.all([toast.logs()]);
    }
};

export const toastOrderStatus = (event: FxOrderSnapshotDownstreamEvent) => {
    const toast = getOrderStatusToastConfig(event);
    if (toast) {
        store.dispatch(pushNotification(toast.notification as Notification));
        // Promise.all([toast.logs()]);
    }
};

export const getOrderRejectedStatusToastConfig = (event: CreateOrderRequestRejectDownstreamEvent) => {
    const { rejectReason } = event;

    const isAccountNotEnabled = rejectReason === 'Account not enabled : [Validation Error!]';

    return {
        logs: async () =>
            logToServer('error', JSON.stringify({ message: `Order Rejected for Account [${event.account}]`, event })),
        notification: {
            type: 'warn',
            body: (
                <div>
                    <p className="text-neutral-400 mt-2">
                        {isAccountNotEnabled ? 'You can not trade because your account is not enabled.' : rejectReason}
                    </p>
                </div>
            ),
            title: 'Order Rejected',
            created_at: new Date()
        }
    };
};

export const getOrderStatusToastConfig = (event: FxOrderSnapshotDownstreamEvent) => {
    const {
        // securityId,
        qty,
        price,
        stopPx,
        orderId,
        id,
        info,
        currency,
        leg,
        securityCode,
        orderType,
        orderStatus,
        side: orderSide,
        timeInForce,
        lastQty,
        cumQty,
        leavesQty,
        orderMetadata,
        account
    } = event;

    const type = OrderType[orderType] as keyof typeof OrderType;
    const side = Side[orderSide] as keyof typeof Side;
    const status = OrderStatus[orderStatus] as keyof typeof OrderStatus;

    const slippage = orderMetadata.find((meta) => meta.key === 'SLIPPAGE')?.value || 0;
    const strategy = orderMetadata.find(({ key }) => key === 'ORIGINAL_STRATEGY_ORDER_TYPE')?.value || '';
    const parentStrategy = orderMetadata.find(({ key }) => key === 'PARENT_STRATEGY_ID')?.value || '';

    const formatQty = getQtyFormatter();

    // const fmtPrice = formatQty.format(price);
    const fmtLegPrice = formatQty.format(leg[0]?.price);
    const fmtQuantity = formatQty.format(qty);
    const fmtFilledAmount = formatQty.format(lastQty);
    const fmtRemainingAmount = formatQty.format(leavesQty);
    const fmtAccumulatedAmount = formatQty.format(cumQty);

    const ccy = getAssetName(currency);
    const [ccy1, ccy2] = securityCode.split('/');

    const ccy2Order = currency === ccy2;
    const ccy1AssetName = getAssetName(ccy1);
    const ccy2AssetName = getAssetName(ccy2);

    const text = {
        orderType: `${capitalizeAll(type)} Order`,
        side: capitalize(side),
        tif: TimeInForceType[timeInForce].toString(),
        titlePrice: titlePrice(orderType, price, stopPx, slippage)
    };

    let position = '';
    if (ccy2Order) position = `${ccy2AssetName} vs ${ccy1AssetName}`;
    else position = `${ccy1AssetName} vs ${ccy2AssetName}`;

    const title = `${text.orderType} to ${text.side} ${fmtQuantity} ${position} ${text.titlePrice}`;
    const isExecutedByStopOrder = [OrderType.LIMIT, OrderType.MARKET].includes(orderType) && stopPx > 0;
    const isPartOfOcoOrder = !!(strategy || parentStrategy);
    const formattedParentStrategy = parentStrategy.replace('STRATEGYORDER:PARENT:', '');

    const notification = {
        created_at: new Date(),
        isExecutedByStopOrder,
        isPartOfOcoOrder,
        account
    };

    switch (status) {
        case 'NEW': {
            return {
                logs: async () =>
                    logToServer(
                        'info',
                        JSON.stringify({ message: `${title} Placed for Account [${event.account}]`, event })
                    ),
                notification: {
                    ...notification,
                    type: 'success',
                    body: (
                        <div>
                            {orderType === OrderType.STOP_LIMIT && <p>Max Slippage: {slippage}</p>}
                            <p>Duration: {text.tif}</p>
                            <p>OrderId: {orderId}</p>
                            {isPartOfOcoOrder && <OCOReference formattedParentStrategy={formattedParentStrategy} />}
                            {isExecutedByStopOrder && <p className="text-neutral-400 mt-2">Executed by a Stop Order</p>}
                        </div>
                    ),
                    title: `${title} Placed`
                }
            };
        }
        case 'FILLED': {
            return {
                logs: async () =>
                    logToServer(
                        'info',
                        JSON.stringify({ message: `${title} Fully Filled for Account [${event.account}]`, event })
                    ),
                notification: {
                    ...notification,
                    type: 'success',
                    body: (
                        <div>
                            <p>Fill price: {fmtLegPrice}</p>
                            <p>
                                Filled Amount: {fmtFilledAmount} {ccy}
                            </p>
                            <p>
                                Remaining Amount: {fmtRemainingAmount} {ccy}
                            </p>
                            <p>
                                Accumulated Amount: {fmtAccumulatedAmount} {ccy}
                            </p>
                            <p>OrderId: {orderId}</p>
                            <p>Exec Id: {id}</p>
                            {isPartOfOcoOrder && <OCOReference formattedParentStrategy={formattedParentStrategy} />}
                            {isExecutedByStopOrder && <p className="text-neutral-400 mt-2">Executed by a Stop Order</p>}
                        </div>
                    ),
                    title: `${title} Fully Filled`
                }
            };
        }
        case 'PART_FILLED': {
            return {
                logs: async () =>
                    logToServer(
                        'info',
                        JSON.stringify({ message: `${title} Partially Filled for Account [${event.account}]`, event })
                    ),
                notification: {
                    ...notification,
                    type: 'success',
                    body: (
                        <div>
                            <p>Fill price: {fmtLegPrice}</p>
                            <p>
                                Filled Amount: {fmtFilledAmount} {ccy}
                            </p>
                            <p>
                                Remaining Amount: {fmtRemainingAmount} {ccy}
                            </p>
                            <p>
                                Accumulated Amount: {fmtAccumulatedAmount} {ccy}
                            </p>
                            <p>OrderId: {orderId}</p>
                            <p>Exec Id: {id}</p>
                            {isPartOfOcoOrder && <OCOReference formattedParentStrategy={formattedParentStrategy} />}
                            {isExecutedByStopOrder && <p className="text-neutral-400 mt-2">Executed by a Stop Order</p>}
                        </div>
                    ),
                    title: `${title} Partially Filled`
                }
            };
        }
        case 'REJECTED': {
            return {
                logs: async () =>
                    logToServer(
                        'warn',
                        JSON.stringify({ message: `${title} Rejected for Account [${event.account}]`, event })
                    ),
                notification: {
                    ...notification,
                    type: 'error',
                    body: (
                        <div>
                            <p>Order Id: {orderId}</p>
                            <p>Exec Id: {id}</p>
                            <p className="text-neutral-400 mt-2">{info}</p>
                            {isPartOfOcoOrder && <OCOReference formattedParentStrategy={formattedParentStrategy} />}
                        </div>
                    ),
                    title: `${title} Rejected`
                }
            };
        }
        case 'CANCELED': {
            return {
                logs: async () =>
                    logToServer(
                        'warn',
                        JSON.stringify({ message: `${title} Cancelled for Account [${event.account}]`, event })
                    ),
                notification: {
                    ...notification,
                    type: 'error',
                    body: (
                        <div>
                            <p>
                                Cancelled Amount: {formatQty.format(qty - cumQty)} {ccy}
                            </p>
                            <p>
                                Remaining Amount: {fmtRemainingAmount} {ccy}
                            </p>
                            <p>
                                Accumulated Amount: {fmtAccumulatedAmount} {ccy}
                            </p>
                            <p>OrderId: {orderId}</p>
                            <p>Exec Id: {id}</p>
                            {/* {info && <p className="text-neutral-400 mt-2">{info}</p>} */}
                            {isPartOfOcoOrder && <OCOReference formattedParentStrategy={formattedParentStrategy} />}
                            {isExecutedByStopOrder && <p className="text-neutral-400 mt-2">Executed by a Stop Order</p>}
                        </div>
                    ),
                    title: `${title} Cancelled`
                }
            };
        }
        default:
            break;
    }
};

export const titlePrice = (orderType: OrderType, price: any, stopPx: any, slippage: any) => {
    switch (orderType) {
        case OrderType.LIMIT:
            return ` @ ${price}`;
        case OrderType.STOP_LIMIT:
            return ` @ ${stopPx}`;
        case OrderType.STOP_MARKET:
            return ` @ ${stopPx}`;
        default:
            return '';
    }
};

export const OCOReference = ({ formattedParentStrategy }: any) => {
    return (
        <Fragment>
            <p className="text-neutral-400 mt-2">Part of an OCO Order</p>
            <p className="text-neutral-400 text-xs">{formattedParentStrategy}</p>
        </Fragment>
    );
};

// TESTING DATA

// export const marketOrder = {
//     id: '1370689010652942337',
//     orderId: '1370689009566617600',
//     externalClOrderId: '91e0bfde-c7d3-40d8-8784-cdce07e3044f',
//     originalExternalClOrderId: '',
//     externalOrderId: '1370689009600176128',
//     secondaryExecId: '1370689010208350208',
//     exchangeCode: 'XCEL',
//     securityCode: 'BTC/USD',
//     securityId: 'BTC/USD',
//     securityIdSource: 'EXCHANGE',
//     externalSecurityCode: 'BTC/USD',
//     externalSecurityId: 'BTC/USD',
//     orderType: 1,
//     strategyType: 1,
//     accountType: 1,
//     account: 'ext-dnh@gc.exchange',
//     side: 1,
//     qty: 0.0001,
//     price: 0,
//     currency: 'BTC',
//     trader: 'ext-dnh@gc.exchange',
//     broker: '',
//     giveUp: '',
//     giveIn: '',
//     lastPx: 21705.5,
//     lastQty: 0.00001,
//     cumQty: 0.0001,
//     leavesQty: 0,
//     avgPx: 21705.5,
//     orderStatus: 3,
//     executionType: 2,
//     orderText: '',
//     info: '',
//     handlingInstruction: 1,
//     parentOrderId: '0',
//     orderVersion: 6,
//     isParentOrder: false,
//     celerClOrderId: '1370689009566617600_0',
//     originalCelerClOrderId: '0',
//     isChildOrder: false,
//     createdTimestampUtcInMillis: '1678344740128',
//     updatedTimestampUtcInMillis: '1678344740387',
//     onBehalfOfCompId: '',
//     deliverToCompId: '',
//     externalExchangeCode: 'XCEL',
//     timeInForce: 6,
//     timeInForceExpiry: '',
//     minQty: 0,
//     maxFloor: 0,
//     quoteId: '',
//     settlementDate: '2023-03-13',
//     checkpointTimestamp: [],
//     refId: '0',
//     bookAsFilled: false,
//     productType: 1,
//     destinationKey: 'CELER_PRICE_ENGINE',
//     algoType: '',
//     commission: 0,
//     leg: [
//         {
//             underlyingExchangeCode: 'XCEL',
//             underlyingSecurityCode: 'BTC/USD',
//             underlyingSecurityId: 'BTC/USD',
//             settlementDate: '2023-03-13',
//             qty: 0.0001,
//             side: 1,
//             price: 21705.5,
//             legForwardPts: 0,
//             spotPrice: 21705.5,
//             settlementType: 'SP',
//             maturityDate: ''
//         }
//     ],
//     tradeAllocGroup: [],
//     tradePartiesGroup: [],
//     settlementType: '',
//     splitValueDate: false,
//     splitLegCurrency1: '',
//     splitLegCurrency2: '',
//     splitLegCurrency1SettlementDate: '',
//     splitLegCurrency2SettlementDate: '',
//     orderMetadata: [
//         {
//             key: 'EXECUTION_METHOD',
//             value: 'ESP'
//         },
//         {
//             key: 'TIERCODE',
//             value: 'NO_TIERING'
//         },
//         {
//             key: 'SLIPPAGE',
//             value: '0.5e-0'
//         },
//         {
//             key: 'STREAMCODE',
//             value: 'INTERNAL'
//         },
//         {
//             key: 'MID_RATE',
//             value: '21703.0'
//         },
//         {
//             key: 'destinationKey',
//             value: 'GCE_PROD_DEMO'
//         }
//     ],
//     orderSource: 'OMS_WEBTRADER',
//     settlCurrency: '',
//     rateSource: '',
//     accountName: 'ext-dnh@gc.exchange',
//     stopPx: 0,
//     sourceExecutionId: '2b59fa8b-db8e-4840-a169-8468e6a2b5c3',
//     destinationBackOfficeKey: '',
//     executionInstruction: 1,
//     maxShow: 0,
//     pegPriceType: 1,
//     lastActionedBy: ''
// };

// export const limitOrder = {
//     ...marketOrder,
//     orderType: 2,
//     price: 1
// };
