import InstrumentInfo from '@/components/common/InstrumentInfo';
import { InstrumentConfig } from '@/config/instruments';
import { useAppDispatch } from '@/state/hooks';
import { removeSubscription, TraderMarket } from '@/state/reducers/traderMarketSlice';
import { Combobox, Transition } from '@headlessui/react';
import cn from 'classnames';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { MdExpandMore, MdOutlineRemoveCircle, MdSearch } from 'react-icons/md';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { ListItemProps } from '../MarketList';

interface Option {
    label: string;
    value?: string;
    market?: TraderMarket;
    config?: InstrumentConfig;
    added?: boolean;
}

interface InstrumentSelectorProps {
    options: Option[];
    selected?: Option;
    watchListId: string;
    parentSize?: DOMRectReadOnly;
    onSubmit: (e: TraderMarket) => void;
    onRemove: (e: TraderMarket) => void;
}

const categoryOptions = [
    { label: 'Show All', value: 'All' },
    { label: 'Crypto CFD', value: 'Crypto' },
    { label: 'FX Spot', value: 'FX' },
    { label: 'Index CFD', value: 'Index' },
    { label: 'Comm CFD', value: 'Commodity' }
];

const InstrumentSelector = (props: InstrumentSelectorProps) => {
    const { options, selected, parentSize, watchListId, onSubmit, onRemove } = props;
    const dispatch = useAppDispatch();

    const [query, setQuery] = useState<any>('');
    const [categoryFilter, setCategoryFilter] = useState<any>('All');

    const filteredOptions = useMemo(() => {
        if (query) {
            const temp = query.replace('/', '').toUpperCase();
            return options
                .filter((option) => option.label.replace('/', '').match(temp))
                .filter((option) => option.config?.type === categoryFilter || categoryFilter === 'All');
        }
        return options.filter((option) => option.config?.type === categoryFilter || categoryFilter === 'All');
    }, [options, query, categoryFilter]);

    const { shouldFullWidth, shouldAutoWidth } = useMemo(() => {
        if (!parentSize) return { shouldFullWidth: false, shouldAutoWidth: false };
        else if (parentSize.width >= 300 && parentSize.width < 580)
            return { shouldFullWidth: true, shouldAutoWidth: false };
        else if (parentSize.width < 300) return { shouldFullWidth: true, shouldAutoWidth: true };
        return { shouldFullWidth: false, shouldAutoWidth: false };
    }, [parentSize]);

    const VirtualizedUserMarket = useCallback(
        ({ index, style }: ListItemProps) => {
            const option = filteredOptions[index];
            const type = option.config?.type;

            return (
                <Combobox.Option
                    style={style}
                    className={cn('relative flex items-center justify-between select-none py-2 pl-3 pr-4 group ', {
                        'hover:bg-brand-red text-neutral-200 cursor-pointer': !option.added,
                        'text-neutral-400': option.added
                    })}
                    onClick={() => {
                        if (!option.added) {
                            if (option.market) onSubmit(option.market);
                        }
                    }}
                    value={option}>
                    <div>
                        <div className="flex items-center gap-1">
                            <InstrumentInfo market={option?.market} width="200px" />
                            <span className="block truncate text-sm">{option?.label}</span>
                        </div>
                        <span
                            className={cn('text-xs text-neutral-400', {
                                'group-hover:text-neutral-200': !option.added
                            })}>
                            {type ? `${type} ${type === 'FX' ? 'Spot' : 'CFD'}` : '-'}
                        </span>
                    </div>
                    {option.added && (
                        <span
                            className="cursor-pointer"
                            onClick={() => {
                                onRemove(option.market as any);
                                dispatch(
                                    removeSubscription({
                                        id: watchListId,
                                        market: option.market as any
                                    })
                                );
                            }}>
                            <MdOutlineRemoveCircle className="w-5 h-5 text-brand-red hover:text-brand-red-light" />
                        </span>
                    )}
                </Combobox.Option>
            );
        },
        [filteredOptions]
    );

    const getItemSize = useCallback((index: number) => 56, []);

    const VirtualizedList = useCallback(
        () => (
            <AutoSizer>
                {({ height, width }) => (
                    <VariableSizeList
                        height={height}
                        width={width}
                        itemCount={filteredOptions.length}
                        itemSize={getItemSize}>
                        {VirtualizedUserMarket}
                    </VariableSizeList>
                )}
            </AutoSizer>
        ),
        [filteredOptions, VirtualizedUserMarket]
    );

    return (
        <Combobox value={[]} multiple>
            <div
                className={cn('relative', {
                    'w-full': shouldFullWidth
                })}>
                <div className="relative w-full cursor-default overflow-hidden bg-brand-background-dark border border-neutral-700 hover:border-neutral-600 text-left shadow-md sm:text-xs">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-1">
                        <MdSearch className="h-5 w-5 text-neutral-400" aria-hidden="true" />
                    </div>
                    <Combobox.Input
                        className="w-full border-none py-1 pl-7 pr-10 text-xs leading-5 text-neutral-200 bg-brand-background-dark outline-none placeholder:text-neutral-500"
                        autoComplete="off"
                        autoCorrect="off"
                        placeholder={selected?.label}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-1">
                            <MdExpandMore className="h-5 w-5 text-neutral-400" aria-hidden="true" />
                        </div>
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    afterLeave={() => setQuery('')}>
                    <Combobox.Options className="absolute right-0 mt-2 z-[60] flex cursor-default bg-brand-background-dark border border-neutral-700 text-sm shadow-lg shadow-neutral-900 overflow-clip">
                        <div className="flex flex-col text-xs w-fit border-r border-neutral-600">
                            {categoryOptions.map((option, optionIdx) => {
                                return (
                                    <div
                                        key={optionIdx}
                                        className={cn('cursor-pointer hover:bg-brand-red p-2', {
                                            'bg-brand-red': categoryFilter === option.value
                                        })}
                                        onClick={() => setCategoryFilter(option.value)}>
                                        {option.label}
                                    </div>
                                );
                            })}
                        </div>
                        <div
                            className={cn('flex flex-col max-h-60', {
                                'w-[203px]': !shouldAutoWidth,
                                'w-[140px]': shouldAutoWidth
                            })}>
                            {filteredOptions.length === 0 && query !== '' ? (
                                <div className="relative select-none py-2 px-4 text-neutral-200">Nothing found.</div>
                            ) : (
                                <VirtualizedList />
                            )}
                        </div>
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    );
};

export default InstrumentSelector;
