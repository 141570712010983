import TraderOrderModal from '@/components/modal/TraderOrderModal';
import ModuleWindow from '@/components/mosaic/ModuleWindow';
import ModuleWindowControls from '@/components/mosaic/ModuleWindowControl';
import { ModalOperations } from '@/model/common';
import { useDisclosure } from '@/utils/hooks/useDisclosure';
import { Fragment, useState } from 'react';
import { MdHardware } from 'react-icons/md';
import TickersModuleBody from './TickersModuleBody';

const TickersModule = (props: any) => {
    const { screen, tradingModule } = props;

    const orderDisclosure = useDisclosure(false);

    const [showControls, setShowControls] = useState<boolean>(true);
    const [modalMode, setModalMode] = useState<ModalOperations>('Create');

    return (
        <Fragment>
            <ModuleWindow
                {...props}
                renderToolbar={() => (
                    <div className="flex justify-between items-center w-full h-full border-b border-b-neutral-700 text-neutral-200">
                        <span className="p-2 font-semibold">{tradingModule.title}</span>
                        <div className="flex h-full items-center">
                            <div
                                className="relative cursor-pointer flex py-1 pl-3 pr-8 text-xs leading-5 text-neutral-200 hover:bg-brand-red bg-brand-background-dark border-x border-neutral-700 hover:border-neutral-600"
                                onClick={() => setShowControls(!showControls)}>
                                <span className="text-xs leading-5">{showControls ? 'Collapse' : 'Expand'} Tools</span>
                                <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <MdHardware className="h-4 w-4 rotate-45" />
                                </div>
                            </div>
                            <ModuleWindowControls screen={screen} />
                        </div>
                    </div>
                )}>
                <TickersModuleBody
                    showControls={showControls}
                    modalMode={modalMode}
                    setModalMode={setModalMode}
                    orderDisclosure={orderDisclosure}
                />
            </ModuleWindow>
            <TraderOrderModal opened={orderDisclosure[0]} handlers={orderDisclosure[1]} />
        </Fragment>
    );
};

export default TickersModule;
