import InstrumentInfo from '@/components/common/InstrumentInfo';
import { tickerIdleTimer } from '@/config/config';
import { Modify } from '@/model/common';
import useTicker from '@/utils/hooks/useTicker';
import cn from 'classnames';
import { CSSProperties, useMemo } from 'react';
import { MdStar, MdStarOutline } from 'react-icons/md';
import { useAppDispatch, useAppSelector } from '../../../state/hooks';
import {
    PairMap,
    addFavouritePair,
    removeFavouritePair,
    selectFavouriteMarketPairs,
    setActivePair
} from '../../../state/reducers/marketPairSlice';
import { GetColorIndexesReturn, GetPipIndexesReturn } from '../../../utils/hooks/useInstrument';
import { useTickerIdle } from '../../../utils/hooks/useTickerIdle';
import HighlightedPips from '../../common/HighlightedPips';

interface UserMarketProps {
    market: PairMap;
    style: CSSProperties;
    isActive?: boolean;
    onClick?: () => void;
    onActive?: () => void;
}
const UserMarket = (props: UserMarketProps) => {
    const { market, style, isActive, onClick, onActive } = props;
    const dispatch = useAppDispatch();

    return (
        <div
            style={style}
            className={cn('hover:bg-neutral-700', {
                'bg-brand-background-dark border-y border-neutral-700 pt-[1px]': isActive
            })}
            onClick={() => {
                dispatch(setActivePair(market));
                onClick && onClick();
            }}>
            <div className={cn('cursor-pointer flex text-xs py-1.5 px-2 text-neutral-200')} onClick={onActive}>
                <UserFavourite {...props} />
                <BidAsks {...props} />
            </div>
        </div>
    );
};

export default UserMarket;

type UserFavouriteProps = Modify<
    Omit<UserMarketProps, 'style'>,
    {
        isMobileSubHeader?: boolean;
    }
>;

export const UserFavourite = ({ market, isMobileSubHeader }: UserFavouriteProps) => {
    const dispatch = useAppDispatch();
    const userFavouriteMarkets = useAppSelector(selectFavouriteMarketPairs);

    const isFavourite = useMemo(() => {
        return userFavouriteMarkets.map((market) => market.celer).includes(market.celer);
    }, [market, userFavouriteMarkets]);

    return (
        <div className="min-w-[110px] w-1/3 flex items-center">
            <div
                className="mr-1 cursor-pointer -mt-0.5"
                onClick={(e) => {
                    if (isFavourite) dispatch(removeFavouritePair(market));
                    else dispatch(addFavouritePair(market));

                    e.stopPropagation();
                }}>
                {isFavourite ? (
                    <MdStar className="h-4 w-4 fill-yellow-500" />
                ) : (
                    <MdStarOutline className="h-4 w-4 fill-neutral-100" />
                )}
            </div>
            <div>{market.show}</div>
            <div className="ml-1">
                <InstrumentInfo
                    market={market}
                    offsetOverride={{
                        mainAxis: 0,
                        crossAxis: isMobileSubHeader ? -80 : -80
                    }}
                />
            </div>
        </div>
    );
};

const BidAsks = ({ market }: UserMarketProps) => {
    const { formattedBid, formattedAsk, spread, bidHighlights, askHighlights } = useTicker(market);
    return (
        <>
            <div className="min-w-[80px] flex-1 flex items-center justify-start">
                {formattedBid && <Highlights ticker={formattedBid} highlights={bidHighlights} />}
            </div>
            <div className="min-w-[20px] flex items-center justify-center px-1">{spread || '-'}</div>
            <div className="min-w-[80px] flex-1 flex items-center justify-end">
                {formattedAsk && <Highlights ticker={formattedAsk} highlights={askHighlights} />}
            </div>
        </>
    );
};

interface HighlightsProps {
    ticker: GetPipIndexesReturn;
    highlights?: GetColorIndexesReturn;
}

export const Highlights = ({ ticker, highlights }: HighlightsProps) => {
    // check if either bid or ask in this ticker is stale for more than 5 seconds.
    // if stale for more than 5 seconds, ticker is idle. if idle, use default colour
    const tickerIdle = useTickerIdle(tickerIdleTimer, highlights);
    return (
        <HighlightedPips
            price={ticker.price}
            boldIndexes={ticker.pipIndexes}
            colorIndexes={!tickerIdle ? highlights?.colorIndexes : []}
            color={!tickerIdle ? highlights?.color : 'text-neutral-200'}
        />
    );
};
