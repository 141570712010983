type Environment = 'local' | 'uat' | 'demo' | 'wl' | 'prod';

const spotLogo = window.config?.logo;
const DEMO_HOSTNAME = window.config.envhostname?.demo;
const PORTAL_DEMO_HOSTNAME = window.config.envhostname?.['portal-demo'];
const PROD_HOSTNAME = window.config.envhostname?.prod;
const PORTAL_PROD_HOSTNAME = window.config.envhostname?.['portal-prod'];

export const getEnvironment = (): Environment => {
    if (window && window.location) {
        switch (window.location.hostname) {
            case 'onechart':
                return 'local';
            case 'localhost':
                return 'local';
            case DEMO_HOSTNAME:
                return 'demo';
            case PROD_HOSTNAME:
            default:
                return 'local';
        }
    }

    return 'local';
};

export const getPlatform = () => {
    if (window && window.location) {
        switch (window.location.hostname) {
            default:
                return 'spot';
        }
    }

    return 'spot';
};

export const getPlatformLogo = () => {
    if (window && window.location) {
        switch (window.location.hostname) {
            default:
                return spotLogo;
        }
    }

    return spotLogo;
};

export const getProtocol = () => {
    switch (window.location.hostname) {
        case 'localhost':
            return 'http:';
        default:
            return 'https:';
    }
};

export const getPortalURL = () => {
    if (window && window.location) {
        switch (window.location.hostname) {
            case 'onechart':
                return PORTAL_DEMO_HOSTNAME;
            case 'localhost':
                return 'localhost:3000';
            case DEMO_HOSTNAME:
                return PORTAL_DEMO_HOSTNAME;
            case PROD_HOSTNAME:
                return PORTAL_PROD_HOSTNAME;
            default:
                return PORTAL_DEMO_HOSTNAME;
        }
    }

    return PORTAL_DEMO_HOSTNAME;
};

export const shouldUseNopBalance = ['local', 'demo', 'prod'].includes(getEnvironment());
export const protocol = getProtocol();
export const portalURL = getPortalURL();
export const portalAPI = (url: string) => `${protocol}//${portalURL}${url}`;
