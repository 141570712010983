import {
    MarketDataPriceEvent,
    MarketDataServiceClient,
    MarketDataServiceDefinition
} from '@/compiled_proto/com/celertech/marketdata/api/notification/MarketDataServiceProto';
import { logToServer } from '@/services/LogService';
import { addMultipleMarkets } from '@/services/MarketService';
import { toastLogout } from '@/utils/hooks/useToast';
import { Logger } from '@/utils/logger';
import { callbackOnlyInMainPage } from '@/utils/middleware';
import { convertPriceEvent } from '@/utils/pricebook';
import { grpc } from '@improbable-eng/grpc-web';
import { ClientError, Metadata, Status, createChannel, createClient } from 'nice-grpc-web';
import { Middleware } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { User, controlClearSubscriptions, controlInitSubscriptions } from '../reducers/authSlice';
import { MarketItem, clearOrderBook, updateOrderBook } from '../reducers/celerMarketSlice';
import { fetchBarsAsync, pushPriceChange, resetChart } from '../reducers/chartDataSlice';
import {
    PairMap,
    addBidAsk,
    appendSubscriptions,
    clearBidAsk,
    clearSubscriptions,
    generatePair,
    setActivePair
} from '../reducers/marketPairSlice';
import { AppDispatch, RootState } from '../store';

const livePricesType = window.config.modules.chart?.livePricesType || 'bid';

/**
 *
 * This middleware is responsible for handling everything related to chart data.
 *
 * This includes:
 * Selection and switching market pair => resolve pair (to celer and netdania equivalents), setup subscriptions on celer data
 * Get historical chart data => retrieve initial historical data
 * Get further historical chart data => retrieve further historical data
 *
 *
 * It works together with chartDataSlice mainly.
 *
 */

let retries = 0;
let subscription: AsyncIterable<MarketDataPriceEvent> | null = null;
let subscriptionId: any = null;
let priceChangeFilter = (celerCode: string) => true;
let retryTimeout: NodeJS.Timeout | null = null;

const t = {
    notify: function (n) {}
};

function e() {
    return new Promise((n) => {
        t.notify = n;
    });
}

export async function* asyncIterable() {
    const n = {};
    let i: any = null;
    for (yield n; ; ) {
        const s = await e();
        const r = Date.now();
        if (!i) {
            i = r;
            continue;
        }
        const c = {
            pingMessage: s,
            lastClientPingIntervalInMillis: String(r - i)
        };
        (i = r), yield c;
    }
}

const resubscribe = (error: any, store: any, credentials: User, dispatch: AppDispatch) => {
    const state: RootState = store.getState();
    if (!['loggingIn', 'loggingOut', 'loggedOut'].includes(state.auth.loginStatus)) {
        if (error instanceof ClientError) {
            if ([Status.UNAUTHENTICATED, Status.UNKNOWN].includes(error.code)) {
                Logger({
                    title: `Inbound: Price Resubscription Error - Code: ${error?.code}.`,
                    callback: () => {
                        console.log({ error });
                        console.log(`Logging user out, please contact technical support.`);
                    }
                });
                logToServer(
                    'error',
                    `User [${credentials.username}] has been logged out due to price subscription error - Message: ${error}`
                );
                toastLogout(credentials);
            }
        }
        if (retries < 20) {
            retries += 1;
            if (retryTimeout) clearTimeout(retryTimeout);
            Logger({
                title: 'Inbound: Error on price subscription, retrying connection...',
                callback: () => {
                    console.log({ 'Retries: ': retries });
                }
            });
            logToServer(
                'warn',
                `User [${credentials.username}] has encountered an error on price subscription - Message: ${error}. Retrying connection... (Retry ${retries})`
            );
            retryTimeout = setTimeout(() => {
                retries = 0;
                Logger({
                    title: 'Connection on price subscription has been re-established.',
                    callback: () => {
                        console.log('Retry counter has been reset');
                    }
                });
            }, 60000);
            setTimeout(() => setupMarketSubscription(store, credentials, dispatch), 500);
        } else {
            retries = 0;
            if (retryTimeout) clearTimeout(retryTimeout);
            Logger({
                title: 'Inbound: Error on price subscription. Max retries reached, logging out...',
                callback: () => {
                    console.log('Please try logging in again');
                }
            });
            logToServer(
                'error',
                `User [${credentials.username}] has been logged out after max retries on price subscription - Message: ${error}`
            );
            toastLogout(credentials);
        }
    }
};

const setupMarketSubscription = async (store: any, credentials: User, dispatch: AppDispatch) => {
    subscription = marketDataServiceClient.subscribeToPrices(asyncIterable(), {
        metadata: Metadata({
            'authorization-token': credentials.authToken
        })
    });

    // dispatch action for incoming data - to be reduced in celerMarketSlice
    const currentSubscriptionId = uuidv4();
    subscriptionId = currentSubscriptionId;
    const callback = async (marketSub: MarketDataPriceEvent) => {
        if (!marketSub.isHeartBeat && marketSub.fullSnapshot?.securityCode) {
            const market = generatePair(marketSub.fullSnapshot.securityCode);
            const converted = convertPriceEvent(marketSub);
            if (converted) {
                const timestamp = parseInt(converted.snapshotMillis);
                const bestBid = converted.priceBook.find((pb) => pb.type === 'bids')?.price;
                const bestAsk = converted.priceBook.find((pb) => pb.type === 'asks')?.price;
                const bestMid = converted.priceBook.find((pb) => pb.type === 'midprice')?.price;
                if (bestBid && bestAsk && bestMid) {
                    if (priceChangeFilter(market.celer)) {
                        dispatch(
                            pushPriceChange({
                                symbol: market.netdania,
                                price: livePricesType === 'mid' ? bestMid : bestBid,
                                timestamp
                            })
                        );
                    }
                    dispatch(addBidAsk({ celerPair: market.celer, bid: bestBid, ask: bestAsk }));
                }
                // const state: RootState = store.getState();
                // const subscriptions = state.marketPair.subscriptions;
                dispatch(updateOrderBook({ priceEvent: converted }));
            } else {
                dispatch(clearBidAsk({ celerPair: market.celer }));
                dispatch(clearOrderBook(marketSub.fullSnapshot.securityCode));
            }
        }
    };

    const subscriptionWithCustomFunction: any = {
        ...subscription,
        throw: async (error) => {
            console.error({ 'ChartDataMiddleware subscription': error });
            const state: RootState = store.getState();
            if (!['loggingIn', 'loggingOut', 'loggedOut'].includes(state.auth.loginStatus)) {
                setupMarketSubscription(store, credentials, dispatch);
            }
        }
    };

    (async () => {
        try {
            for await (const result of subscriptionWithCustomFunction) {
                if ((() => subscriptionId !== currentSubscriptionId)()) {
                    Logger({ title: `ChartDataMiddleware: Old Subscriptions Terminated`, callback: () => {} });
                    break;
                    // throw new Error(stopMsg || 'Old Subscriptions Terminated');
                }
                try {
                    callback(result);
                } catch (err) {
                    console.log(err);
                    continue;
                }
            }
        } catch (error) {
            console.error({ AsyncIterable: error });
            if (retryTimeout) clearTimeout(retryTimeout);
            resubscribe(error, store, credentials, dispatch);
        }
    })().catch((error) => {
        console.error({ 'ChartDataMiddleware subscription': error });
        if (retryTimeout) clearTimeout(retryTimeout);
        resubscribe(error, store, credentials, dispatch);
    });
};

const wsChannelUrl = window.config.integration.celertech.websocket;
const wsChannel = createChannel(wsChannelUrl, grpc.WebsocketTransport());
const marketDataServiceClient: MarketDataServiceClient = createClient(MarketDataServiceDefinition, wsChannel);

const ChartDataMiddleware: Middleware = (store) => (next) => async (action) => {
    const dispatch: AppDispatch = store.dispatch;
    const state: RootState = store.getState();
    const credentials = state.auth.user;

    if (controlClearSubscriptions.match(action)) {
        Logger({ title: `ChartDataMiddleware: Clear Subscriptions`, callback: () => {} });
        if (retryTimeout) clearTimeout(retryTimeout);
        retries = 0;
        retryTimeout = null;
        subscription = null;
        dispatch(clearSubscriptions());
    } else if (controlInitSubscriptions.match(action)) {
        Logger({ title: `ChartDataMiddleware: Initialise Subscriptions`, callback: () => {} });
        // If not already listening, setup subscriptions
        if (!subscription && credentials) {
            setupMarketSubscription(store, credentials, dispatch);
        }
    } else if ('marketPair/fetch/fulfilled'.match(action.type) && credentials) {
        Logger({ title: `ChartDataMiddleware: Pairs Fetched`, callback: () => {} });

        callbackOnlyInMainPage(async () => {
            const state: RootState = store.getState();
            const subscriptions = state.marketPair.subscriptions || {};

            const items: MarketItem[] = action.payload;
            const marketPairs = items.map((item) => generatePair(item.securityCode));

            const subscribedPairs = Object.values(subscriptions).map((subInfo: any) => subInfo.pair);
            const pairsToSubscribe = marketPairs.filter(
                (marketPair) => subscribedPairs.indexOf(marketPair.celer) === -1
            );
            const celerPairsToSubscribe = pairsToSubscribe.map((pair) => pair.celer);

            Logger({
                title: 'Outbound: Subscribing to Celer Prices',
                callback: () => {
                    console.log({ celerPairsToSubscribe });
                }
            });

            // await addSubscriptions(credentials, celerPairsToSubscribe, dispatch);
            await throttledSubscriptions(credentials, celerPairsToSubscribe, dispatch);
        });
    } else if (setActivePair.match(action) && credentials) {
        callbackOnlyInMainPage(() => {
            // extract data from action
            const payload = action.payload as PairMap;

            // Override the filter
            priceChangeFilter = (celerCode: string) => celerCode === payload?.celer;

            // // extract data from state
            const currentPair = state.marketPair.activePair;
            const subscriptions = state.marketPair.subscriptions || {};

            // Dispath action to fetch data
            dispatch(resetChart());
            dispatch(fetchBarsAsync(payload));

            // if (currentPair && payload && currentPair.celer !== payload.celer) {
            //     degradeMarket(credentials, currentPair, subscriptions, dispatch).then(() => {
            //         upgradeMarket(credentials, payload?.celer, dispatch);
            //     });
            // }
        });
    }

    // Pass on to next middlewares in line
    next(action);
};

export default ChartDataMiddleware;

const throttledSubscriptions = async (credentials, celerPairsToSubscribe, dispatch) => {
    const chunkSize = 10;
    for (let i = 0; i < celerPairsToSubscribe.length; i += chunkSize) {
        const chunk = celerPairsToSubscribe.slice(i, i + chunkSize);
        await addSubscriptions(credentials, chunk, dispatch);
    }
};

const addSubscriptions = async (credentials, chunk, dispatch) => {
    try {
        const addedSubscriptions = {};
        const addedMarketItemList = await addMultipleMarkets(credentials, chunk);
        if (addedMarketItemList) {
            addedMarketItemList.forEach((addedMarketItem) => {
                addedSubscriptions[addedMarketItem.subscriptionId] = {
                    type: 'tob',
                    pair: addedMarketItem.securityCode,
                    exchange: addedMarketItem.exchangeCode,
                    settlementType: addedMarketItem.settlementType
                };
            });
        }
        dispatch(appendSubscriptions(addedSubscriptions));
    } catch (error) {
        dispatch(clearSubscriptions());
    }
};
