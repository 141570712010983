import { OrderRoutingOrderEvent } from '@/compiled_proto/com/celertech/orderrouting/api/notification/OrderServiceProto';

import { ExecutionType } from '@/compiled_proto/com/celertech/orderrouting/api/enums/ExecutionTypeProto';
import { FxOrderSnapshotDownstreamEvent } from '@/compiled_proto/com/celertech/orderrouting/api/order/DownstreamOrderProto';
import { getExecutionTypeToastConfig, toastExecutionType } from '@/helpers/executionTypeHelper';
import {
    getOrderRejectedStatusToastConfig,
    getOrderStatusToastConfig,
    toastOrderRejectedStatus,
    toastOrderStatus
} from '@/helpers/orderStatusHelper';
import { BlotterItem } from '@/model/blotters';
import { processBatchOrderEvent } from '@/state/reducers/blotterSlice';
import { Notification, pushBatchNotifications } from '@/state/reducers/notificationSlice';
import { AppDispatch, RootState } from '@/state/store';
import { Logger } from '@/utils/logger';

export const parseOrderUpdate = (previousEvents: BlotterItem[], orderUpdate: OrderRoutingOrderEvent) => {
    const { fxOrderSnapshotDownstreamEvent, createOrderRequestRejectDownstreamEvent } = orderUpdate;
    Logger({
        title: 'Inbound: OrderStatusMiddleware Event',
        callback: () => console.log({ fxOrderSnapshotDownstreamEvent, createOrderRequestRejectDownstreamEvent })
    });
    if (fxOrderSnapshotDownstreamEvent) {
        const { executionType } = fxOrderSnapshotDownstreamEvent;
        if (executionType === ExecutionType.REPLACED) {
            // TODO: Handle more types, as of now it only handles Order Amendment
            toastExecutionType(previousEvents, fxOrderSnapshotDownstreamEvent);
        } else {
            // TODO: Distinguish order type
            // TODO: Type absolute quantity, write out buy or sell
            toastOrderStatus(fxOrderSnapshotDownstreamEvent);
        }
    } else if (createOrderRequestRejectDownstreamEvent) {
        toastOrderRejectedStatus(createOrderRequestRejectDownstreamEvent);
    }
};

export const parseBatchOrderUpdate = (
    previousEvents: BlotterItem[],
    orderUpdates: OrderRoutingOrderEvent[],
    dispatch: AppDispatch
) => {
    const consoleObject: Record<number, any> = {};
    orderUpdates.forEach((orderUpdate, index) => {
        const { fxOrderSnapshotDownstreamEvent, createOrderRequestRejectDownstreamEvent } = orderUpdate;
        consoleObject[index] = { fxOrderSnapshotDownstreamEvent, createOrderRequestRejectDownstreamEvent };
    });

    Logger({
        title: 'Inbound: OrderStatusMiddleware Events [Batched Update]',
        callback: () => console.log(consoleObject)
    });

    const processedToasts = orderUpdates.map((orderUpdate) => {
        const { fxOrderSnapshotDownstreamEvent, createOrderRequestRejectDownstreamEvent } = orderUpdate;
        if (fxOrderSnapshotDownstreamEvent) {
            const { executionType } = fxOrderSnapshotDownstreamEvent;
            if (executionType === ExecutionType.REPLACED) {
                // TODO: Handle more types, as of now it only handles Order Amendment
                return getExecutionTypeToastConfig(previousEvents, fxOrderSnapshotDownstreamEvent);
            } else {
                // TODO: Distinguish order type
                // TODO: Type absolute quantity, write out buy or sell
                return getOrderStatusToastConfig(fxOrderSnapshotDownstreamEvent);
            }
        } else if (createOrderRequestRejectDownstreamEvent) {
            return getOrderRejectedStatusToastConfig(createOrderRequestRejectDownstreamEvent);
        } else return null;
    });

    const processedNotifications = processedToasts
        .filter((toasts) => toasts?.notification)
        .map((toast) => toast?.notification as Notification);

    dispatch(pushBatchNotifications(processedNotifications.filter((notification) => notification) as Notification[]));

    // Promise.all(processedToasts.reverse().map((toast) => toast?.logs()));
};

interface PushNotificationsParams {
    latestEvents: OrderRoutingOrderEvent[];
    previousEvents: BlotterItem[];
    state: RootState;
    dispatch: AppDispatch;
}

export const pushNotifications = ({ latestEvents, previousEvents, dispatch }: PushNotificationsParams) => {
    if (latestEvents.length === 1) parseOrderUpdate(previousEvents, latestEvents[0]);
    else parseBatchOrderUpdate(previousEvents, latestEvents, dispatch);

    // Update blotter
    const processibleOrderEvents = latestEvents
        .filter(
            (orderUpdate) =>
                orderUpdate.fxOrderSnapshotDownstreamEvent &&
                !orderUpdate.createOrderRequestRejectDownstreamEvent?.rejectReason
        )
        .map((orderUpdate) => orderUpdate.fxOrderSnapshotDownstreamEvent as FxOrderSnapshotDownstreamEvent);

    dispatch(processBatchOrderEvent(processibleOrderEvents));
};
