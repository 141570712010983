import { useAppSelector } from '@/state/hooks';
import { selectFavouriteMarketPairs, selectMarketPairs } from '@/state/reducers/marketPairSlice';
import { CSSProperties, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import Input from '../../inputs/Input';
import UserMarket from './UserMarket';

import TabFilters from '@/components/common/TabFilters';
import { MarketListFilter } from '@/components/modules/MarketList';
import instrumentConfig from '@/config/instruments';
import { PairMap } from '@/state/reducers/tickerSlice';
import { useDidUpdate } from '@/utils/hooks/useDidUpdate';
import { memoize } from '@mantine/core';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

interface MarketListProps {
    onClick?: () => void;
}

export const marketListFilters: { label: keyof typeof MarketListFilter; value: MarketListFilter }[] = [
    { label: 'All', value: MarketListFilter.All },
    { label: 'FX', value: MarketListFilter.FX },
    { label: 'Crypto', value: MarketListFilter.Crypto },
    { label: 'Index and Comm CFDs', value: MarketListFilter['Index and Comm CFDs'] }
];

export default function MarketList(props: MarketListProps) {
    const { onClick } = props;

    const userMarkets = useAppSelector(selectMarketPairs);
    const userFavouriteMarkets = useAppSelector(selectFavouriteMarketPairs);

    const [filter, setFilter] = useState('');
    const [marketListFilter, setMarketListFilter] = useState(MarketListFilter.All);

    const userFavouriteMarketsCeler = useMemo(() => {
        return userFavouriteMarkets.map((market) => market.celer);
    }, [userFavouriteMarkets]);

    const sortedFavouriteMarkets = useMemo(() => {
        const temp = [...userFavouriteMarkets];
        temp.sort((a, b) => {
            if (a.celer < b.celer) return -1;
            if (a.celer > b.celer) return 1;
            return 0;
        });
        return temp;
    }, [userFavouriteMarkets]);

    const sortedUserMarkets = useMemo(() => {
        const temp = userMarkets.filter((market) => !userFavouriteMarketsCeler.includes(market.celer));
        return [...sortedFavouriteMarkets, ...temp];
    }, [userMarkets, userFavouriteMarketsCeler]);

    const { filteredUserMarkets, hasFxInstruments, hasCryptoInstruments, hasIndexInstruments } = useMemo(() => {
        const temp = filter.replace('/', '').toUpperCase();
        let returnValues = sortedUserMarkets;
        const fxInstruments = sortedUserMarkets.filter((market) => {
            const config = instrumentConfig[market.celer];
            return config?.type === 'FX';
        });
        const cryptoInstruments = sortedUserMarkets.filter((market) => {
            const config = instrumentConfig[market.celer];
            return config?.type === 'Crypto';
        });
        const indexInstruments = sortedUserMarkets.filter((market) => {
            const config = instrumentConfig[market.celer];
            return ['Index', 'Commodity'].includes(config?.type);
        });

        const hasFxInstruments = fxInstruments.length > 0;
        const hasCryptoInstruments = cryptoInstruments.length > 0;
        const hasIndexInstruments = indexInstruments.length > 0;

        if (marketListFilter === MarketListFilter.All) returnValues = sortedUserMarkets;
        else if (marketListFilter === MarketListFilter.FX) returnValues = fxInstruments;
        else if (marketListFilter === MarketListFilter.Crypto) returnValues = cryptoInstruments;
        else if (marketListFilter === MarketListFilter['Index and Comm CFDs']) returnValues = indexInstruments;

        return {
            filteredUserMarkets: returnValues.filter((market) => market.show.replace('/', '').match(temp)),
            hasFxInstruments,
            hasCryptoInstruments,
            hasIndexInstruments
        };
    }, [sortedFavouriteMarkets, sortedUserMarkets, marketListFilter, filter]);

    const listRef = useRef({}) as VariableSizeList;
    const rowHeights = useRef({});

    const getRowHeight = useCallback((index) => rowHeights.current[index] || 32, [rowHeights]);
    const setRowHeight = useCallback(
        (index, size) => {
            listRef.current.resetAfterIndex(0);
            rowHeights.current = { ...rowHeights.current, [index]: size };
        },
        [listRef, rowHeights]
    );

    const UserMarketRow = useCallback(
        ({ data, index, style }: ListRowItemProps) => {
            const rowRef = useRef({}) as MutableRefObject<HTMLDivElement>;
            const { items } = data;

            const market = items[index];
            const rowHeight = getRowHeight(index);

            useEffect(() => {
                if (rowRef.current && rowHeight !== 63) setRowHeight(index, 32);
            }, [rowRef]);

            return (
                <UserMarket
                    key={`${market.celer}-${index}`}
                    ref={rowRef}
                    style={style}
                    market={market}
                    onClick={onClick}
                    onActive={() => {
                        if (rowHeight === 32) setRowHeight(index, 63);
                        else setRowHeight(index, 32);
                    }}
                    isActive={rowRef.current && rowHeight === 63}
                />
            );
        },
        [getRowHeight, setRowHeight]
    );

    const itemData = useMemo(() => createItemData(filteredUserMarkets), [filteredUserMarkets]);

    const VirtualizedList = useCallback(
        () => (
            <AutoSizer>
                {({ height, width }) => (
                    <VariableSizeList
                        height={height}
                        width={width}
                        itemCount={itemData.items.length}
                        itemData={itemData}
                        itemSize={getRowHeight}
                        ref={listRef}>
                        {UserMarketRow}
                    </VariableSizeList>
                )}
            </AutoSizer>
        ),
        [itemData]
    );

    useDidUpdate(() => {
        rowHeights.current = {};
    }, [userFavouriteMarketsCeler]);

    return (
        <div className="h-full w-full flex flex-col">
            <TabFilters.Main>
                <TabFilters.Filter
                    selectedFilter={marketListFilter}
                    setSelectedFilter={setMarketListFilter}
                    filter={marketListFilters[0]}
                />
                {hasFxInstruments && (
                    <TabFilters.Filter
                        selectedFilter={marketListFilter}
                        setSelectedFilter={setMarketListFilter}
                        filter={marketListFilters[1]}
                    />
                )}
                {hasCryptoInstruments && (
                    <TabFilters.Filter
                        selectedFilter={marketListFilter}
                        setSelectedFilter={setMarketListFilter}
                        filter={marketListFilters[2]}
                    />
                )}
                {hasIndexInstruments && (
                    <TabFilters.Filter
                        selectedFilter={marketListFilter}
                        setSelectedFilter={setMarketListFilter}
                        filter={marketListFilters[3]}
                    />
                )}
            </TabFilters.Main>
            <div className="p-2">
                <Input placeholder="Search" icon={<MdSearch />} onChange={(e) => setFilter(e.target.value)} />
            </div>

            <div className="w-full flex text-gray-300 text-sm pl-2 pr-3.5">
                <div className="min-w-[110px] w-1/3">Pair</div>
                <div className="min-w-[80px] flex-1 flex justify-start">Bid</div>
                <div className="min-w-[20px] flex items-center justify-center px-1">
                    {/* <div className="hidden 2xl:block">Spread</div> */}
                </div>
                <div className="min-w-[80px] flex-1 flex justify-end">Ask</div>
            </div>

            <div className="relative self-stretch grow basis-full">
                <VirtualizedList />
            </div>
        </div>
    );
}

export interface ListItemProps {
    index: number;
    style: CSSProperties;
}

interface ListRowItemProps extends ListItemProps {
    data: { items: PairMap[] };
}

const createItemData = memoize((items) => ({ items }));
